import React from 'react';
import { Link } from 'gatsby'


const Tuition = ({data}) => {
  return (
    <section className="py-24">
            <div className="mx-auto">
                <h1 className="text-cnh-script-500 text-4xl text-center mb-5">Tuition</h1>
                <p className="text-center mb-2"><span className="text-cnh-script-500 text-4xl">${data.amount}</span><span
                        className="text-cnh-script-500 text-xl">/{data.frequency}</span></p>
                <p className="text-cnh-script-400 text-center text-lg font-light">4 sessions per month</p>
                <div className="py-10">
                    <Link to='/apply' className="cursor-pointer text-base text-white" >
                        <div className="rounded-lg bg-cnh-accent-flamingo w-32 mx-auto p-3">
                            <p className="text-base text-white text-center">Apply Now</p>
                        </div>
                    </Link>
                </div>
            </div>
        </section>
  )
}

export default Tuition