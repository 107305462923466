import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const CourseInstructors = ({ instructors }) => {
  return (
    <section class="py-24 bg-cnh-feedback-link_water">
            <div class="mx-auto">
                <h1 class="text-cnh-script-500 text-4xl text-center mb-5">Our Awesome Instructor{instructors.length > 1 && 's'}</h1>
                <div className="flex flex-row flex-wrap justify-center -space-x-2">
                {
                  instructors.map(instructor => {
                    console.log("Instructor: ", instructor.name)
                    const image = getImage(instructor.profilePicUrl)
                     return (<div key={instructor.name}>
                      {/* <div className="flex flex-col md:self-end justify-center items-center space-y-4 py-4 md:p-4"> */}
                        <GatsbyImage image={image} className="w-32 h-32 rounded-full overflow-hidden opacity-95 border-2 border-white"/>
                        {/* <span class="text-cnh-script-400 text-lg">{instructor.name}</span> */}
                      {/* </div> */}
                    </div>)
                  })
                }
                </div>
            </div>
        </section>
  )
}

export default CourseInstructors;