import React from 'react';

import TimeIcon from '../images/svg/time-icon.svg';
import LevelsIcon from '../images/svg/levels-icon.svg';
import RemoteIcon from '../images/svg/remote-icon.svg';
import StudentTeacherRatioIcon from '../images/svg/student-teacher-ratio-icon.svg';

const sessionCount = (isScratch) => {
    if (isScratch) {
        return (
            <>
               <TimeIcon />
                <div className="text-center">
                    <h4 className="text-base text-cnh-script-500 font-semibold">20 Sessions</h4>
                </div>
            </>
        )
    } else {
        return (
            <>
                <LevelsIcon />
                <div className="text-center">
                    <h4 className="text-base text-cnh-script-500 font-semibold">4 levels of learning</h4>
                    <h4 className="text-base text-cnh-script-500 font-regular">2 team projects</h4>
                </div>
            </>
        )
    }
}

const CourseHightLights = ({isScratch}) => {
  return (
    <section className="py-24">
      <div className="container flex flex-col md:flex-row justify-center md:space-x-10 md:space-y-0 space-y-10 mx-auto">
          <div className="flex-1 flex flex-col space-y-5 items-center">
              {sessionCount(isScratch)}
          </div>
          <div className="flex-1 flex flex-col space-y-5 items-center">
              <RemoteIcon className="" />
              <h4 className="text-base text-cnh-script-500 font-semibold">Live Interactive Classes</h4>
              <p className="px-6 text-base text-cnh-script-400 text-center">Real time classes, not a passive
                  experience.
                  Students
                  interact with instructor and other
                  students
                  during the class.</p>
              <p className="text-sm text-cnh-script-500 font-medium">Online classes only</p>
          </div>
          <div className="flex-1 flex flex-col space-y-5 items-center">
              <StudentTeacherRatioIcon />
              <h4 className="text-base text-cnh-script-500 font-semibold">4:1 Students Teacher Ratio</h4>
          </div>
      </div>
  </section>
  )
}

export default CourseHightLights;
